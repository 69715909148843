import * as React from "react";

// markup
const Footer = () => {
  return (
    <div className="bg-black">
      <p className="text-white text-center mb-0 py-2">Copyright © 2021, Kauneuskerros Kastanja</p>
    </div>
  );
};

export default Footer;
